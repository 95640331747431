import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import Layout from '../../layouts/layout';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import Project from '../../models/Project';
import './work.scss';

const QUERY_PROJECT = graphql`
  {
    allProjectsJson {
      edges {
        node {
          projectId
          cardTitle
          services
          background
          color
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

interface QueryResults {
  allProjectsJson: { edges: { node: Project }[] };
}

const Work = (): JSX.Element => {
  const {
    allProjectsJson: { edges },
  }: QueryResults = useStaticQuery(QUERY_PROJECT);

  return (
    <Layout title="Our Projects">
      <div className="work">
        <Header />
        <div className="work-container">
          <h2>OUR PROJECTS</h2>
          <div className="work-projects">
            {edges.map(({ node }) => (
              <React.Fragment key={node.projectId}>
                <ProjectCard
                  title={node.cardTitle}
                  desc={node.services}
                  imgFluid={node.thumbnail.childImageSharp.gatsbyImageData}
                  background={node.background}
                  color={node.color}
                  data-sal="zoom-in"
                  data-sal-duration="300"
                  data-sal-easing="ease-in-out"
                  onClick={() => {
                    navigate(`/work/${node.projectId}`);
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default Work;
